<template>
  <transition @enter="slideInBottom" @leave="slideOutBottom" :css="false" mode="out-in">
    <div v-if="showOverlay === 'slideOverview'" class="w-full h-auto md:h-full fixed top-0 md:flex md:flex-row md:justify-center md:items-center md:inset-0 z-30 font-arial text-new-black pointer-events-none">
      <div class="relative w-full md:w-5/12 md:min-w-100 h-screen min-h-screen md:min-h-0 md:h-auto md:max-h-80  md:overflow-y-auto md:bg-new-yellow md:rounded-t-3xl md:rounded-3xl md:p-5 pointer-events-auto scrollbar flex flex-col">

        <!-- Content -->
        <div class="w-full h-1/6" @click="closeOverview"></div>
        <div class="w-full h-5/6 bg-new-yellow rounded-t-3xl">

          <!-- Heading -->
          <div class="text-xl cursor-pointer px-5 pt-5" @click="closeOverview">schließen</div>
          <div class="mt-6 font-faro font-bold text-3xl px-5">Lernspot Übersicht</div>
          <div class="px-5">{{lernspot}}</div>

          <!--  Shortcuts -->
          <div class="flex flex-col mt-5 font-arial h-3/4 overflow-y-auto scrollbar p-5">
            <div v-for="(slide, index) in slides" :key=slide class="flex flex-row items-center mb-5" @click="changeSlide(slide, index)">
              <div v-if="currentSlideProgress.includes(slide.id) || currentMaxSlideId === slide.id || currentSlideId === slide.id || index === 0" class="transition duration-150 ease-in-out w-8 h-8 mr-5 text-base rounded-full border border-new-black flex-shrink-0 flex flex-row justify-center items-center cursor-pointer md:hover:bg-new-black md:hover:text-white"
                :class="{'ring ring-new-black bg-white': currentSlideId === slide.id , 'bg-green-500 text-white' : currentSlideProgress.includes(slide.id), 'bg-white' : index === 0}">
                {{index + 1}}
              </div>
              <div v-else class="transition duration-150 ease-in-out w-8 h-8 mr-5 text-base bg-white rounded-full border border-new-black flex-shrink-0 flex flex-row justify-center items-center opacity-50">
                {{index + 1}}
              </div>
              <div v-if="slide.Titel">{{slide.Titel}}</div>
            </div>
          </div>
          <!--  Shortcuts -->

        </div>
        <!-- Content -->

      </div>
    </div>
  </transition>
</template>

<script>
// Mixins
import slideInOutBottom from '../../../mixins/animations/slideInOutBottom.vue';
import iosVibrations from '../../../mixins/iosVibrations.vue';

export default {
  name: 'slideOverview',
  props: ['lernspot', 'slides', 'currentSlideId'],
  mixins: [slideInOutBottom, iosVibrations],

  computed: {
    showOverlay() {
      return this.$store.state.layout.showOverlay;
    },
    selectedLernpfadId() {
      return this.$store.state.learning.selectedLernpfadId;
    },
    selectedLernspotId() {
      return this.$store.state.learning.selectedLernspotId;
    },
    lernpfade() {
      return this.$store.state.profile.me.userData.data.attributes.lernpfadProgress.lernpfade;
    },
    lernpfadIndex() {
      return this.lernpfade.findIndex((lernpfad) => lernpfad.id === this.selectedLernpfadId);
    },
    lernspotIndex() {
      return this.lernpfade[this.lernpfadIndex].lernspots.findIndex((lernspot) => lernspot.id === this.selectedLernspotId);
    },
    currentSlideProgress() {
      if (this.lernpfadIndex === -1 || this.lernspotIndex === -1) {
        return [];
      }
      return this?.lernpfade[this.lernpfadIndex]?.lernspots[this.lernspotIndex]?.slides;
    },
    currentMaxSlideId() {
      return this?.lernpfade[this.lernpfadIndex]?.lernspots[this.lernspotIndex]?.currentMaxSlideId;
    },
  },

  methods: {
    closeOverview() {
      this.iosVibrate(0);
      this.$store.commit('showOverlay', null);
    },

    changeSlide(slide, index) {
      if (this.currentSlideProgress.includes(slide.id) || this.currentMaxSlideId === slide.id || this.currentSlideId === slide.id || index === 0) this.$emit('changeSlide', index);
    },
  },
};
</script>
